@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@400;600&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #181c24 !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.728) !important;
}
p {
  color: rgba(255, 255, 255, 0.728);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.nav-link:hover {
  border-bottom: 2px solid white;
}
.stack__card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #181c24 !important;
  /* Change from auto to scroll */
  min-height: 100vh;
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.stack__card::-webkit-scrollbar {
  display: none;
}

.bg-main {
  background-color: #181c24 !important;
}
.readmore-btn {
  color: #1a1a1a !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.vh-100 {
  height: 100vh;
}

@media (max-width: 991.98px) {
  .vh-100 {
    height: auto;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.d-none {
  display: none !important;
}

.d-lg-block {
  display: block !important;
}

.d-lg-none {
  display: none !important;
}

@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
    min-height: 100vh;
  }
  .d-lg-none {
    display: none !important;
  }
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.min-vh-100 {
  min-height: 100vh;
}

.contact-info h4 {
  position: relative;
  padding-left: 110px;
}

.contact-info h4::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 90px;
  height: 3px;
  background-color: rgb(255, 255, 255);
}

.office-address,
.email {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.office-address i,
.email i {
  font-size: 24px;
  margin-right: 10px;
}

.slider-wrapper {
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 15px;
}

.bg-dark {
  background-color: #343a40 !important;
}

.text-black {
  color: #000 !important;
}

.company-list li {
  position: relative;
  padding-left: 24px;
  margin-bottom: 10px;
}

.company-list li i {
  position: absolute;
  left: 0;
  top: 0;
}

.newsletter-input {
  background-color: #000;
  border: none;
  color: #fff;
  outline: none;
  padding: 8px;
  flex-grow: 1;

  transition: background-color 0.3s;
  border: 1px solid #343a40;
}

.newsletter-input:focus {
  background-color: #fff;
  color: #000;
}
.bg-image {
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  z-index: -1;
}

.bg-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 767px) {
  .bg-image-container {
    width: 100%;
    height: auto !important;
  }
  .top-image {
    height: 100vh !important;
  }
}
.scroll-indicator {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999999;
}

.arrow {
  font-size: 20px;
  color: #ffff;
  cursor: pointer;
}
.arrow :hover {
  color: #888888 !important;
}

.arrow-up {
  margin-bottom: 4px;
}

.arrow-down {
  margin-top: 4px;
}

.mouse-icon {
  width: 24px;
  height: 40px;
  border: 2px solid #ffff;
  border-radius: 12px;
  position: relative;
}

.mouse-icon::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: #ffff;
  border-radius: 50%;
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
  }
}

.zoom-in-out {
  animation: zoomInOut 1s infinite;
}
.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Adjust the z-index value if needed, to ensure the navbar stays on top of other elements */
}
/* .nav-link.active {
  border-bottom: 2px solid #ffffff;
}*/
.nav-item,
.nav-link {
  cursor: pointer;
}
.active {
  border-bottom: 2px solid #ffffff;
}
.circle-col {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.circle-start {
  background: #ffffff00;
  height: 350px;

  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  border-top: 5px solid #2f7d69;
  position: relative;
}
.circle-start::before {
  content: "";
  position: absolute;
  top: calc(50% - 10px);
  left: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid #2f7d69;
  background-color: transparent;
}

.circle-end {
  background: #ffffff00;
  height: 350px;

  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  border-top: 5px solid #2f7d69;
  position: relative;
}
.circle-end::before {
  content: "";
  position: absolute;
  top: calc(50% - 10px);
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid #2f7d69;
  background-color: transparent;
}

.circle-bottom {
  background: #ffffff00;

  height: 350px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  border-bottom: 5px solid #2f7d69;
}

@media (max-width: 875px) {
  .circle-start,
  .circle-start::before,
  .circle-end,
  .circle-end::before,
  .circle-bottom {
    border: none;
    height: fit-content !important;
  }
}
.shadow-box {
  box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.296);
}
.zoom-effect {
  transition: transform 0.3s;
}

.zoom-effect:hover {
  transform: scale(1.1);
}
.slider-container {
  position: relative;
}

.slider-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
}

.slider-dots-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.slick-dots li.slick-active .slider-dot {
  width: 12px;
  height: 12px;
}

.slide {
  position: relative;
}

.slide-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px;
  height: fit-content !important;
}
.navbar-light {
  transition: background-color 0.4s;
}
.slick-track {
  /* display: flex !important;
  align-items: center !important;
  justify-content: center !important; */
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: #fff;
}

.footer a {
  color: #fff;
  text-decoration: none;
  margin: 0 0.5rem;
}

.footer-logo {
  max-width: 150px;
}

.fa {
  font-size: 1.5rem;
  margin: 0 0.5rem;
}

.fa:hover {
  color: #ccc;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
.stack__card {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.cookie-policy-container {
  position: relative;
  width: 100%;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image-container-img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(255, 255, 255, 0.8) 50%
  );
}

.cookie-policy-heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  text-align: center;
}
.cookie-policy-heading span{
  font-size: 3.1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
   -webkit-text-stroke: 2px rgb(0, 0, 0)

}

.cookie-consent-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  width: 90%;
}

.modal-buttons {
  display: flex;
  justify-content: center;

  margin-top: 10px;
}
